@font-face 
{
    font-family: 'Noto Sans';
    src: url('../../assets/fonts/NotoSans-Regular.ttf');

    font-weight: normal;
    font-style: normal;
}

*
{
    font-family: 'Noto Sans';
    color: white;
}

body 
{
	margin: 0;
    text-align: center;
	background: rgb(56, 56, 56);
    overflow-x: hidden;
	overflow-y: auto;
}

h1 
{
	color: white;
	top: 50%; left: 50%;
	position: absolute;

	transform: translateX(-50%) translateY(-50%) scale(2, 2);
	transition: 0.5s ease-in-out;
}

h1.top 
{
    top: 0;
    transform: translateX(-50%);
}

line 
{
    stroke-width: 5px;
    stroke-dasharray: 100px;
    stroke-dashoffset: 100px;
    stroke-linecap: round;
}

a 
{
    color: inherit;
    display: inline-block;
}
    
    a.d 
    {
        text-decoration: none;
    }

h2 
{
	margin: 0;
}

h3
{
	margin-bottom: 0;
}

p
{
	margin: 0;
}

    #intro
    {
        width: 90%;
        height: 30px;
        margin: 60px auto auto auto;
        line-height: 30px;
    }

    #Portfolio
    {
        opacity: 0;
        transition: 0.5s;
    }

        #Start
        {
            position: relative;
            color: white;
            margin-top: 100px;
        }

            .copyright
            {
                margin-left: auto;
                margin-right: auto;
            }
            

    .mainImage
    {
        width: 200px; height: auto;
    }

        .lang-button 
        {
            display: inline-block;
            cursor: pointer;
        }

            .lang-button:hover
            {
                transform: scale(1.02);
                transition: all 0.2s;
            }

    #lang1
    {
        border-color: #7750A9;
    }

    #lang2
    {
        border-color: #8A72BE;
    }

    #lang3
    {
        border-color: #80D2E6;
    }

    #lang4
    {
        border-color: #50BED2;
    }

    #others
    {
        border-color: #f1ff71;
    }

    #details 
    {
        display: flex;
        justify-content: center;
        margin: 0px;
        padding: 0px;
    }

    .table 
    {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        min-width: 350px;
        max-width: 75%;
        margin-top: 50px;

        transition: all 0.2s ease;
    }
      
    .item-con 
    {
        position: relative;
        min-width: 350px;
        max-width: 350px;
        min-height: 450px;
        padding: 5px;
        margin-left: 35px;
        margin-right: 35px;
        margin-bottom: 80px;
        justify-content: center;
        overflow: hidden;
        overflow-y: visible;
        text-align: center;
        border-style: solid;
        border-radius: 20px;
        border-width: 3px;
        background-color: #203A43;
        opacity: 0.5;
        transition: all 0.2s;
        z-index: 3;
        cursor: pointer;
    }

        .item-con:hover
        {
            opacity: 1.0;
            transform: scale(1.1);
        }

        .item-container
        {
            height: 450px;
            width: 350px;
        }

        .item-container *:hover
        {
            transform: scale(1.02);
        }

        .item-con img
        {
            z-index: 1;
            height: 200px;
            width: 315px;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 20px;
            object-fit: cover;
            transform: scale(1.1);
        }

            .item-con img:hover
            {
                transform: scale(1.1);
                transition: all 0.2s;
            }

        .item-con .tip
        {
            margin-top: 1px;
            margin-bottom: 0px;
            color: gray;
            font-size: smaller;
            transition: all 0.2s;
        }
    
            .item-con p, 
            .item-con h3
            {
                transition: all 0.2s;
            }

        .item-con h3
        {
            margin-top: 5px;
            margin-bottom: 2px;
            font-size: 17px;
        }

        .item-con p
        {
            font-size: 15px;
            color: #bfbfbfbf;
            word-spacing: 1px;
        }

        .item-con .ref
        {
            position: absolute;
            margin-top: 20px;
            top: 87.5%;
            transform: translate(-50%, 0%);
            transition: all 0.2s;
        }

            .item-con .ref:hover
            {
                font-size: larger;
                color: #e48080;
            }

            .item-con.filtered 
            {
                opacity: 0.6;
                animation-name: fadeInExpand;
                animation-duration: 0.5s;
                animation-iteration-count: 1;
                transition: all 0.2s;
            }

                .item-con.filtered:hover 
                {
                    -webkit-animation-play-state: paused;
                    -moz-animation-play-state: paused;
                    -o-animation-play-state: paused;
                    animation-play-state: paused;

                    opacity: 1.0;
                    transform: scale(1.1);
                }

/* modal */
#textBox {
    position: fixed;
    pointer-events: none;
    opacity: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 90%;
    height: 80%;
    max-width: 1920px;
    z-index: 1000;
    background: #0F2027;

    border-radius: 20px;
    border: 2px solid;

    transition: opacity .25s linear;
    box-shadow: 0 0 100px 10px black inset;
}

    #textBox.shown {
        opacity: 1;
        pointer-events: initial;
        overflow-y: scroll;
    }

    #textBox h2 {
        margin-top: 25px;
        width: 80%;
        font-size: 40px;
    }

    #textBox p 
    {
        padding: 10px;
        line-height: 1.5;
        font-size: 20px;
        width: 80%;
        overflow: scroll;
    }

    #textBox #tbimg {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 25px;
        object-fit: contain;
        padding: 1px;
        width: 50%;
        max-height: 400px;
        border: solid 1px;
        border-radius: 10px;
        border-color: rgb(125, 125, 125);
        background-color: white;
    }

        #textBox #tbimg #image
        {
            border: solid 1px;
            border-radius: 10px;
            width: 49.5%;
            height: 99%;
        }

    .close
    {
        background: #ffffff;
        border: 3px solid #999999;
        color: #0F2027;
        border-radius: 100%;
        cursor: pointer;
        width: 40px; 
        line-height: 20px;
        top: 10px; right: 10px;
        margin: 0px;
        position: absolute;
        text-decoration: none;
        z-index: 2;

        font-weight: bold;
        font-size: 25px;
        line-height: 37px;
        text-align: center;

        -webkit-transition:all ease 0.5s;
        -moz-transition: all ease 0.5s;
        transition:all ease 0.5s;
    }

        .close:hover
        {
            background-color: #cccccc;
        }

/* Background */
#bg
{
    min-height: 100%; min-width: 1024px;
    width: 100%; height: auto;
    background-size: cover;
    background: linear-gradient(to bottom, #0F2027, #203A43, #2C5364);
    position: fixed; top: 0; left: 0;

    z-index: -2;
}

.singleLine
{
    width: 100%;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #000000;
	border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #000000;
}

::-webkit-scrollbar-thumb
{
	background-color: rgb(70, 32, 100);
	border-radius: 10px;
	background-image: -webkit-linear-gradient(90deg,
	                                          rgba(0, 0, 0, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(0, 0, 0, .2) 50%,
											  rgba(0, 0, 0, .2) 75%,
											  transparent 75%,
											  transparent)
}
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover 
  {
    background-color: rgb(58, 28, 81);
    background-image: -webkit-linear-gradient(90deg,
	                                          rgba(0, 0, 0, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(0, 0, 0, .2) 50%,
											  rgba(0, 0, 0, .2) 75%,
											  transparent 75%,
											  transparent)
  }

  .item-con::-webkit-scrollbar
  {
    width: 0px;
  }

@keyframes fadeInExpand {
    0% {
        opacity: 0;
        transform: scale(0);
    }

    50% {
        opacity: 0.3;
        transform: scale(1.1);
    }

    100% {
        opacity: 0.6;
        transform: scale(1);
    }
}
