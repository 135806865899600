@font-face 
{
    font-family: 'Noto Sans';
    src: url('../../assets/fonts/NotoSans-Regular.ttf');

    font-weight: normal;
    font-style: normal;
}

/* A = href a. Change properties of links */
a
{
    text-decoration: none;
}

/* body
{
    height: 0;

    overflow-x: hidden;
	overflow-y: auto;
} */

html, body, #aboutContainer 
{
    width: 100%;
    min-height: 100%;
}

    /* Main area */
    #aboutContainer
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        font-family: 'Noto Sans';
        text-align: center;

        z-index: -1;
    }

        /* Header */
        #header
        {
            display: flex;
            flex-direction: column;
            align-items: center;

            -moz-transition: -moz-transform 0.325s ease-in-out, -moz-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
            -webkit-transition: -webkit-transform 0.325s ease-in-out, -webkit-filter 0.325s ease-in-out, opacity 0.325s ease-in-out;
            transition: transform 0.325s ease-in-out, filter 0.325s ease-in-out, opacity 0.325s ease-in-out;

            position: fixed;
            top: 50%;left: 50%;

            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            width: 60%;
        }

            .mainPic
            {
                border-radius: 50%;
                border: 1px solid rgb(255, 255, 255);
                width: 200px; height: 200px;
                overflow: hidden;
                position: relative;
                transform: scale(1);
                margin: 20px;
                
                opacity:0;
                -webkit-animation: fadeIn ease-in 1; 
                -moz-animation: fadeIn ease-in 1;
                animation: fadeIn ease-in 1;

                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;

                -webkit-animation-duration: 2s;
                -moz-animation-duration: 2s;
                animation-duration: 2s;

                -webkit-transition: transform 0.3s;
                -moz-transition: transform 0.3s;
                transition: transform 0.3s;
            }

                .circleImage
                {
                    width: 100%; height: 100%;
                    object-fit: cover;
                }

                .circleText
                {
                    background-color: rgba(0, 0, 0, 0.6);
                    color: rgba(255, 255, 255, 0.774);
                    display: flex;
                    opacity: 0;
                    border-radius: 50%;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    width: 100%; height: 100%;
                    top: 0;
                    
                    -webkit-transition: opacity 0.5s;
                    -moz-transition: opacity 0.5s;
                    transition: opacity 0.5s;

                    text-align: center;
                }

                    .circleText:hover
                    {
                        opacity: 1;
                    }

                .mainPic:hover
                {
                    -webkit-transition: scale (1.1);
                    -moz-transform: scale (1.1);
                    transform: scale (1.1);

                    -webkit-transition: transform 0.2s;
                    -moz-transition: transform 0.2s;
                    transition: transform 0.2s;

                    border-top-color: rgba(255, 0, 0, 0.4);
                    border-left-color: rgba(255, 0, 0, 0.4);
                    border-bottom-color: rgba(255, 0, 0, 0.4);
                    border-right-color: rgba(255, 0, 0, 0.4);

                    -webkit-transition: transform 0.2s, 
                    border-top-color 0.18s linear,
                    border-left-color 0.18s linear 0.2s, 
                    border-bottom-color 0.18s linear 0.4s,
                    border-right-color 0.18s linear 0.3s;
                    -moz-transition: transform 0.2s, 
                    border-top-color 0.18s linear,
                    border-left-color 0.18s linear 0.2s, 
                    border-bottom-color 0.18s linear 0.4s,
                    border-right-color 0.18s linear 0.3s;
                    transition: transform 0.2s, 
                    border-top-color 0.18s linear,
                    border-left-color 0.18s linear 0.2s, 
                    border-bottom-color 0.18s linear 0.4s,
                    border-right-color 0.18s linear 0.3s;
                }

                .content
                {
                    border-style: solid;
                    border-color: #ffffff;
                    border-top-width: 1px; border-bottom-width: 1px;
                    border-left-width: 0px; border-right-width: 0px;

                    -webkit-animation:textBox ease-in 1;
                    -moz-animation:textBox ease-in 1;
                    animation:textBox ease-in 1;
                    font-size: 26px;
                
                    -webkit-animation-duration: 3s;
                    -moz-animation-duration: 3s;
                    animation-duration: 3s;

                    -moz-transition: max-height 0.75s ease, padding 0.75s ease, opacity 0.325s ease-in-out;
                    -webkit-transition: max-height 0.75s ease, padding 0.75s ease, opacity 0.325s ease-in-out;
                    -ms-transition: max-height 0.75s ease, padding 0.75s ease, opacity 0.325s ease-in-out;
                    transition: max-height 0.75s ease, padding 0.75s ease, opacity 0.325s ease-in-out;
                    
                    padding: 50px;
                    height: 100%;
                    letter-spacing: 2px;
                    color: #d3d3d3;
                }
            
            .buttons
            {
                display: flex;
                width: 40%;
                min-width: 200px;
                max-width: 600px;
                
                margin-bottom: 0;
                list-style: none;
                padding-left: 0;
                border: solid 1px #ffffff;
                border-radius: 4px;
                position: relative;
                margin-top: 10px;
                cursor: pointer;

                -webkit-animation: fadeIn ease-in 1; 
                -moz-animation: fadeIn ease-in 1;
                animation: fadeIn ease-in 1;

                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;

                -webkit-animation-duration: 2s;
                -moz-animation-duration: 2s;
                animation-duration: 2s;
            }

                .buttons li
                {
                    display: block;
                    padding-left: 0;
                    border-left: solid 1px #ffffff;
                    min-width: 50%; 
                    text-transform: uppercase;
                    letter-spacing: 0.1rem;
                    color: #ffffff;
                    border-bottom: 0;

                    background-color: rgba(0, 0, 0, 0.175);

                    -webkit-transition: all linear 0.15s;
                    -moz-transition: all linear 0.15s;
                    transition: all linear 0.15s;
                }

                        .buttons li:hover 
                        {
                            background-color: rgba(255, 255, 255, 0.275);
                        }

                        .buttons li:active 
                        {
                            background-color: rgba(255, 255, 255, 0.375);
                        }

                        .buttons li p 
                        {
                            margin: inherit;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            text-align: center;
                            font-size: 20px;
                            display: inline-flexbox;
                            vertical-align: middle;
                        }

    #mainText
    {
        position: absolute;
        display: flex;
        max-width: 950px;
        min-height: 200px;
        /*top: 25%;*/
        margin-top: 1000px;
        /*margin: auto;*/
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        z-index: 1;
    }

        #mainText p, #mainText h2
        {
            color: black;
        }

        #mainBubble
        {
            display: none;
            position: relative;
            font-size: 20px;
            padding: 1%;
            
            border-radius: 10px;
            border: solid;
            border-color: #000000;
        }

            .resumeButton
            {
                background: #000000;
                border: 3px solid #000000;
                width: 200px; height: 50px;

                color: rgb(255, 255, 255);
                margin: 10px;

                font-size: 30px;
                font-weight: bold;

                -webkit-animation: floatButton 1.2s linear alternate infinite;
                -moz-animation: floatButton 1.2s linear alternate infinite;
                animation: floatButton 1.2s linear alternate infinite;
            }

                .resumeButton:hover
                {
                    background-color: #ffffff;
                    color: #000000;
                    border: 3px dashed #000000;
                    transition: all 0.4s;
                }

            .closeButton
            {
                background: #d75f70;
                border: 3px solid #999999;
                border-radius: 1000px;
                cursor: pointer;
                width: 35px; height: 35px;
                top: 10px; right: 10px;
                display: inline-block;
                position: absolute;
                font-size: 25px;
                line-height: 37px;
                transition: all 0.2s;
            }

                .closeButton:hover
                {
                    background-color: #752626;
                }


    .copyright
    {
        position: fixed;
        top: 95%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index:0;
    }

    /* Background */
    #aboutbg
    {
        min-height: 100%; min-width: 1024px;
        width: 100%; height: auto;

        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        background: linear-gradient(to top, rgb(143, 115, 202), rgb(26, 26, 26));
        position: fixed; top: 0; left: 0;

        z-index: -2;
    }
        
        .circles
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
        
            .circles li
            {
                position: absolute;
                display: block;
                list-style: none;
                width: 20px;
                height: 20px;
                animation: BGAnimation 20s linear infinite;
                bottom: -25%;
            }
        
                .circles li:nth-child(1)
                {
                    left: 25%;
                    width: 80px; height: 80px;
                    animation-delay: 0s;
                }
                
                
                .circles li:nth-child(2)
                {
                    left: 10%;
                    width: 20px; height: 20px;
                    animation-delay: 2s;
                    animation-duration: 12s;
                }
                
                .circles li:nth-child(3)
                {
                    left: 70%;
                    width: 20px; height: 20px;
                    animation-delay: 4s;
                }
                
                .circles li:nth-child(4)
                {
                    left: 40%;
                    width: 60px; height: 60px;
                    animation-delay: 0s;
                    animation-duration: 18s;
                }
                
                .circles li:nth-child(5)
                {
                    left: 65%;
                    width: 20px; height: 20px;
                    animation-delay: 0s;
                }
                
                .circles li:nth-child(6)
                {
                    left: 75%;
                    width: 110px; height: 110px;
                    animation-delay: 3s;
                }
                
                .circles li:nth-child(7)
                {
                    left: 35%;
                    width: 150px; height: 150px;
                    animation-delay: 7s;
                }
                
                .circles li:nth-child(8)
                {
                    left: 50%;
                    width: 25px; height: 25px;
                    animation-delay: 15s;
                    animation-duration: 45s;
                }
                
                .circles li:nth-child(9)
                {
                    left: 20%;
                    width: 15px; height: 15px;
                    animation-delay: 2s;
                    animation-duration: 35s;
                }
                
                .circles li:nth-child(10)
                {
                    left: 85%;
                    width: 150px; height: 150px;
                    animation-delay: 0s;
                    animation-duration: 11s;
                }

    #black
    {
        min-height: 100%; min-width: 100%;
        width: 100%; height: auto;

        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        background-color: #000000;
        position: fixed; top: 0; left: 0;

        z-index: -3;
    }

/* Animations/Effects. Used by setting the HTML class="EFFECT NAME" */

/* Fade in effect */
.fadeIn
{
    opacity: 0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: 0.75s;
    -moz-animation-duration: 0.75s;
    animation-duration: 0.75s;
}

@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.slowFadeIn
{
    opacity:0;
    -webkit-animation: fadeIn ease-in 1;
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;

    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    animation-duration: 1.5s;
}

@-webkit-keyframes slowFadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes slowFadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes slowFadeIn { from { opacity:0; } to { opacity:1; } }

@-webkit-keyframes openSquish { from { transform: scaleY(0); } to { transform: scaleY(1); } }
@-moz-keyframes openSquish { from { transform: scaleY(0); } to { transform: scaleY(1); } }
@keyframes openSquish { from { transform: scaleY(0); } to { transform: scaleY(1); } }

/* Hover button effect */
@keyframes floatButton 
{
    0%   
    {
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
        transform: translateY(-3px);
    }

    100% 
    {
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.75);
        transform: translateY(3px); 
    }
}

/* Store Button Animation */
@keyframes glowing 
{
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

/* Background Animation */
@keyframes BGAnimation 
{
    0%
    {
        background: rgba(90, 74, 126, 0.5);
        transform: translateY(0) rotate(0deg);
        border-radius: 0;
    }

    25%
    {
        background: rgba(84, 61, 101, 0.25);
    }

    50%
    {
        background: rgba(58, 48, 76, 0.25);
    }

    75%
    {
        background: rgba(42, 35, 51, 0.15);
    }

    100%
    {
        background: rgb(26, 26, 26, 0);
        transform: translateY(-1000px) rotate(720deg);
        border-radius: 75%;
    }
}

::-webkit-scrollbar
        {
            display: none;
        }
